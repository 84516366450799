<ng-container *transloco="let t; read 'permit.list'">

    <a *ngIf="item" class="item" [routerLink]="routerLink" routerLinkActive="router-link-active">
        <div *ngIf="item.companyName" class="premium-identifier"></div>

        <div class="content">
            <div class="item__header" [class.item__header--base]="!item.companyName">
                <div class="item__header__left">
                    <div *ngIf="item.companyName" class="item__header__left__premium">
                        <img class="item__header__left__premium--icon" src="assets/images/icons/premium-icon-permit.svg">
                    </div>
                    <div class="item__header__left__status" [class]="'item__header__left__status--' + item.publicStatus"></div>
                </div>
                <div class="item__header__center">
                    <div *ngIf="item.companyName" class="item__header__center__company" [innerHTML]="item.companyName"></div>
                    <div class="item__header__center__workplace" [class.item__header__center__workplace--is-demo]="item.isDemo" [innerHTML]="fullPermitName(item)"></div>
                    <div *ngIf="item.workplaceAdditionalInfo" class="item__header__center__company" [innerHTML]="item.workplaceAdditionalInfo"></div>
                </div>
                <div class="item__header__right" [class.item__header__right--base]="!item.companyName">
                    <div *ngIf="item.isDemo" class="item__header__right__demo"></div>
                    <div class="item__header__right__flammable" [class.item__header__right__flammable--not]="!item.isFlammableHotWork"></div>
                    <div class="item__header__right__id" [innerHTML]="t('id', { id: item.id })"></div>
                </div>
            </div>
            <div class="item__content" [class.item__content--is-flammable]="!isNotFlammableAndCompleted" [class.item__content--is-demo]="isDemoAndCompleted">
                <div *ngIf="item.endDate || item.uniqueParticipantCount > 1">
                    <div *ngIf="item.endDate" class="item__content__time">
                        <div [innerHTML]="t((item.publicStatus === PermitPublicStatus.ongoing ? 'validTo' : 'expired'), { endDate: item.endDate | haDate:'dateTimeStandard' })"></div>
                        <div *ngIf="item.publicStatus === PermitPublicStatus.ongoing" class="item__content__time__post" [innerHTML]="t('validPostMonitorTime')"></div>
                    </div>
                </div>
                <ng-container *ngIf="item.isFlammableHotWork">
                    <div class="item__content__emergency">
                        <div class="item__content__emergency__number">
                            <div class="icon"></div>
                            <div>{{ item.emergencyNumber }}</div>
                        </div>
                        <div *ngIf="item.emergencyLocation" class="item__content__emergency__location">
                            <div class="icon"></div>
                            <div>{{ item.emergencyLocation }}</div>
                        </div>
                        <div *ngIf="item.disabledFireAlarm" class="item__content__emergency__fire-alarm">
                            <div class="icon"></div>
                            <div>{{ item.disabledFireAlarm }}</div>
                        </div>
                    </div>

                    <div class="item__divider" [class.item__click]="routerLink">
                        <hr class="item__divider__line">
                    </div>

                    <div class="item__content__holder">
                        <div class="item__content__info">
                            <div class="item__content__info__section">
                                <div class="item__content__info__section__title" [innerHTML]="t('workMethods')"></div>
                                <div class="item__content__info__section__value" [innerHTML]="item.workMethods"></div>
                            </div>
                            <div *ngIf="item.isFlammableHotWork && item.uniqueParticipantCount > 1" class="item__content__info__section">
                                <div class="item__content__info__section__title" [innerHTML]="t('participantCount')"></div>
                                <div class="item__content__info__section__value" [innerHTML]="item.uniqueParticipantCount"></div>
                            </div>
                        </div>
                        <div class="item__content__info">
                            <div *ngIf="item.roles.length > 0 || item.isIssuer" class="item__content__info__section">
                                <div class="item__content__info__section__title" [innerHTML]="item.roles?.length > 1 ? t('myRoles') : t('myRole')"></div>
                                <div class="item__content__info__section__value" [innerHTML]="roles"></div>
                            </div>
                            <div *ngIf="item.roles.length == 0 && !item.isIssuer" class="item__content__info__section">
                                <div class="item__content__info__section__title" [innerHTML]="t('issuerTitle')"></div>
                                <div class="item__content__info__section__value" [innerHTML]="item.issuerName"></div>
                            </div>
                            <div class="item__content__info__section">
                                <div class="item__content__info__section__title" [innerHTML]="t('postWorkMonitoringTitle')"></div>
                                <div class="item__content__info__section__value" [innerHTML]="item.postWorkMonitoringTime"></div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <div *ngIf="item.publicStatus === PermitPublicStatus.ongoing || item.publicStatus === PermitPublicStatus.expiredNotSigned" class="item__progress-bar">
                    <div class="item__progress-bar__status" [class.item__progress-bar__full]="getProgress(item.startDate, item.endDate) === 100" [style.width.%]="getProgress(item.startDate, item.endDate)"></div>
                </div>

                <div *ngIf="item.roles?.length == 0 && !item.isIssuer" class="item__owner" [innerHTML]="t('issuerInitials', {name: getInitials(item.issuerName) })"></div>
            </div>
        </div>
    </a>
</ng-container>
