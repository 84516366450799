<ng-container *transloco="let t; read:'permit.team.add'">
    <div class="person" [class.person--disabled]="isDisabled" (click)="toggleStagePerson(person)">
        <div class="person__row">
            <div class="person__details">
                <div class="person__details__name">{{person.firstName}} {{person.lastName}}</div>
                <ng-container *ngIf="person.certificateExpired || !person.canBeAdded else dates">
                    <div *ngIf="person.certificateExpired" class="person__details__date">{{t('errorMessages.notValid')}}
                    </div>
                    <div *ngIf="!person.canBeAdded" class="person__details__date">{{t('errorMessages.anotherRole')}}</div>
                </ng-container>
                <ng-template #dates>
                    <div class="person__details__date">{{person.birthDate | haDate:'date' }}</div>
                    <div class="person__details__date" [class.person__details__date--expiring]="person.certificateExpireSoon">
                        {{t('certifiedTo', { date:
                        person.expirationDate | haDate:'date' })}}
                    </div>
                </ng-template>
            </div>
            <div *ngIf="person.isFromColleague" class="person__from--colleague"></div>
            <div class="person__checkmark" [class.person__checkmark--disabled]="isDisabled" [class.person__checkmark--selected]="staged"></div>
        </div>
        <div class="person__row">
            <div class="person__concept" *ngIf="person.concept > 1">
                <div class="person__icon person__icon--triangle-exclamation">
                    <span class="person__icon--text" [innerHTML]="t('otherConcept')"></span>
                </div>
            </div>
        </div>
    </div>
</ng-container>
