import { PersonRole } from "@ha/data/basic";

import { PermitPerson } from "../../shared/models/permit-person.model";
import {
    FindCertifiedPersonQuery,
    FireWatchersNotNeededCommand,
    RemovePersonFromPermitCommand,
    ToggleAllowSwitchingCommand,
} from "../models/permit-team-commands.model";
import { SignPermitWithSignatureCommand } from "../models/sign-permit-with-signature-command";

export namespace PermitTeamActions {
    export class AddStagedPersonsToPermit {
        public static readonly type = "[PermitTeamActions] AddStagedPersonsToPermit";
        constructor(public permitId: number, public role: PersonRole) {}
    }

    export class ClearCreatePersonFormData {
        public static readonly type = "[PermitTeamActions] ClearCreatePersonFormData";
    }

    export class CreateCertificateAndStagePerson {
        public static readonly type = "[PermitTeamActions] CreateCertificateAndStagePerson";
        constructor(public permitId: number, public role: PersonRole) {}
    }

    export class FindPerson {
        public static readonly type = "[PermitTeamActions] FindPerson";
        constructor(public query: FindCertifiedPersonQuery) {}
    }

    export class GetRecentTeamMembers {
        public static readonly type = "[PermitTeamActions] GetRecentTeamMembers";
        constructor(public permitId: number, public take: number, public includeColleagues: boolean, public restart: boolean = false, public query: string = "") {}
    }

    export class GetTeam {
        public static readonly type = "[PermitTeamActions] GetTeam";
        constructor(public permitId: number) {}
    }

    export class RemovePerson {
        public static readonly type = "[PermitTeamActions] RemovePerson";
        constructor(public command: RemovePersonFromPermitCommand) {}
    }

    export class ResetFind {
        public static readonly type = "[PermitTeamActions] ResetFind";
    }

    export class ToggleAllowSwitching {
        public static readonly type = "[PermitTeamActions] ToggleAllowSwitching";
        constructor(public command: ToggleAllowSwitchingCommand) {}
    }

    export class SetFireWatchersNotNeeded {
        public static readonly type = "[PermitTeamActions] SetFireWatcherNotNeeded";
        constructor(public command: FireWatchersNotNeededCommand) {}
    }

    export class SetAddPersonView {
        public static readonly type = "[PermitTeamActions] SetAddPersonView";
        constructor(public role: PersonRole) {}
    }

    export class SignPermitWithSignature {
        public static readonly type = "[PermitTeamActions] SignPermitWithSignature";
        constructor(public command: SignPermitWithSignatureCommand) {}
    }

    export class StagePerson {
        public static readonly type = "[PermitTeamActions] StagePerson";
        constructor(public person: PermitPerson) {}
    }

    export class UnstageAll {
        public static readonly type = "[PermitTeamActions] UnstageAll";
    }

    export class UnstagePerson {
        public static readonly type = "[PermitTeamActions] UnstagePerson";
        constructor(public certificateId: number) {}
    }
}
