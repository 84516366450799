import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from "@angular/core";
import { PersonRole } from "@ha/data/basic";
import { HaModalService, ModalComponent } from "@ha/ui/common";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";

import {
    AddPersonModalComponent,
    AddPersonModalComponentData,
} from "../../../permit-team/components/add-person-modal/add-person-modal.component";
import {
    RemovePersonModalComponent,
    RemovePersonModalComponentData,
} from "../../../permit-team/components/remove-person-modal/remove-person-modal.component";
import { PermitTeam } from "../../../permit-team/models/permit-team.model";
import { PermitTeamActions } from "../../../permit-team/states/permit-team.action";
import { PermitTeamSelectors } from "../../../permit-team/states/permit-team.selectors";
import { PermitStepActions } from "../../../permit/states/permit-step.action";
import { PermitStepSelectors } from "../../../permit/states/permit-step.selectors";
import { PermitPerson } from "../../../shared/models/permit-person.model";
import { PermitCreateSelectors } from "../../states/permit-create.selectors";
import { FormBuilder, Validators } from "@angular/forms";
import { PermitUserActions } from "../../../shared/states/permit-user.action";

@UntilDestroy()
@Component({
    selector: "pmt-team",
    templateUrl: "permit-team.component.html",
    styleUrls: ["permit-team.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PermitTeamComponent implements OnInit {
    @ViewChild("roleSwitchingModal")
    private roleSwitchingModal: ModalComponent;

    @Input() public permitId: number;

    @Select(PermitTeamSelectors.team)
    public permitTeam$: Observable<PermitTeam>;

    @Select(PermitCreateSelectors.isPremium)
    public isPremium$: Observable<boolean>;

    @Select(PermitCreateSelectors.isDemo)
    public isDemo$: Observable<boolean>;

    public canProceed = false;

    public PersonRole = PersonRole;

    public form = this.fb.group({
        allowSwitching: [false, Validators.required],
    });

    constructor(private fb: FormBuilder, private modalService: HaModalService, private store: Store) {}

    public ngOnInit(): void {
        this.store.dispatch(new PermitUserActions.GetCurrentPerson());
        this.store.dispatch(new PermitTeamActions.GetTeam(this.store.selectSnapshot(PermitStepSelectors.permitId)));

        this.permitTeam$
            .pipe(untilDestroyed(this))
            .subscribe((team) => {
                this.form.controls.allowSwitching.setValue(team.allowSwitching);
            });

        this.store.select(PermitTeamSelectors.teamValid)
            .pipe(untilDestroyed(this))
            .subscribe((valid) => {
                this.canProceed = valid;
            });
    }

    public add(role: PersonRole): void {
        this.store.dispatch(new PermitTeamActions.SetAddPersonView(role));
        this.modalService.open(AddPersonModalComponent, true, <AddPersonModalComponentData>{
            allowExternalCertificates: this.store.selectSnapshot(PermitCreateSelectors.allowExternalCertificates),
            permitId: this.permitId,
            role: role,
        });
    }

    public removePerson(permitPerson: PermitPerson, role: PersonRole) {
        this.modalService.open(RemovePersonModalComponent, false, <RemovePersonModalComponentData>{
            permitId: this.permitId,
            person: permitPerson,
            role: role,
        });
    }

    public toggleAllowSwitching() {
        this.store.dispatch(new PermitTeamActions.ToggleAllowSwitching({ permitId: this.permitId }));
    }

    public setFireWatcherNeeded() {
        this.store.dispatch(new PermitTeamActions.SetFireWatchersNotNeeded({ permitId: this.permitId, fireWatchersNotNeeded: false, comment: "" }));
    }

    public continue(): void {
        this.store.dispatch(new PermitStepActions.GoForward());
    }

    public goBack(): void {
        this.store.dispatch(new PermitStepActions.GoBackwards());
    }

    public showRoleSwitchingModal(): void {
        this.roleSwitchingModal.openModal();
    }
}
