import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { PersonRole } from "@ha/data/basic";
import { Select, Store } from "@ngxs/store";
import { BehaviorSubject, Observable } from "rxjs";

import { PermitPersonWithStatus } from "../../../shared/models/permit-person.model";
import { PermitTeamActions } from "../../states/permit-team.action";
import { PermitTeamSelectors } from "../../states/permit-team.selectors";
import { PermitUserActions } from "../../../shared/states/permit-user.action";
import { PermitUserSelectors } from "../../../shared/states/permit-user.selectors";
import { PermitCreateSelectors } from "../../../permit-create/states/permit-create.selectors";

@Component({
    selector: "pmt-last-used-persons-list",
    templateUrl: "last-used-persons-list.component.html",
    styleUrls: ["last-used-persons-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class LastUsedPersonsListComponent implements OnInit {
    @Input()
    public permitId: number;

    @Input()
    public role: PersonRole;

    @Select(PermitTeamSelectors.recentTeamMembers)
    public recentTeamMembers$: Observable<PermitPersonWithStatus[]>;

    @Select(PermitTeamSelectors.recentTeamMembersFullyLoaded)
    public recentTeamMembersFullyLoaded$: Observable<boolean>;

    public query: string = "";

    @Select(PermitTeamSelectors.recentTeamMembersSearchResult)
    public result$: Observable<PermitPersonWithStatus[]>;

    @Select(PermitUserSelectors.isPremiumIssuer)
    public isPremiumIssuer$: Observable<boolean>;

    @Select(PermitCreateSelectors.isPremium)
    public isPremium$!: Observable<boolean>;

    private INITIAL_TAKE = 40;
    private LOAD_MORE_TAKE = 10;
    private take = this.INITIAL_TAKE;

    public loading$ = new BehaviorSubject<boolean>(false);
    public includeColleagues: boolean = false;

    constructor(private store: Store) {}

    public ngOnInit(): void {
        var isPremium = this.store.selectSnapshot(PermitCreateSelectors.isPremium) ?? false;
        var premiumIssuer = this.store.selectSnapshot(PermitUserSelectors.isPremiumIssuer) ?? false;
        this.includeColleagues = isPremium && premiumIssuer;
        this.loadTeamMembers(true);
    }

    private loadTeamMembers(restart: boolean): void {
        this.loading$.next(true);
        this.store.dispatch(new PermitTeamActions.GetRecentTeamMembers(this.permitId, this.take, this.includeColleagues, restart, this.query.toLowerCase())).subscribe(() => {
            this.loading$.next(false);
        });
    }

    public loadMore(): void {
        this.take = this.LOAD_MORE_TAKE;
        this.loadTeamMembers(false);
    }

    public search(query: string) {
        this.query = query;
        this.loadTeamMembers(true);
    }

    public clearSearchResults() {
        this.query = "";
        this.search("");
    }

    public toggleColleagues(): void {
        this.loadTeamMembers(true);
    }
}
