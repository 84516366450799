import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseValidationResult } from "@ha/ui/forms";
import { Observable } from "rxjs";

import { PermitPerson } from "../../shared/models/permit-person.model";
import {
    CreateCertificateAndPersonCommand,
    CreateCertificateAndPersonResponse,
} from "../models/create-certificate-person.model";
import {
    AddStagedPersonsToPermitCommand,
    AddStagedPersonsToPermitResponse,
    FindCertifiedPersonQuery,
    FindCertifiedPersonResult,
    FireWatchersNotNeededCommand,
    RemovePersonFromPermitCommand,
    ToggleAllowSwitchingCommand,
} from "../models/permit-team-commands.model";
import { PermitTeam } from "../models/permit-team.model";
import { SignPermitWithSignatureCommand } from "../models/sign-permit-with-signature-command";

@Injectable({ providedIn: "root" })
export class PermitTeamService {

    constructor(private httpClient: HttpClient) {}

    public addStagedPersonsToPermit(command: AddStagedPersonsToPermitCommand): Observable<AddStagedPersonsToPermitResponse> {
        return this.httpClient.post<AddStagedPersonsToPermitResponse>("/api/PermitPerson/AddStagedPersonsToPermit", command);
    }

    public createCertificateAndPerson(command: CreateCertificateAndPersonCommand): Observable<CreateCertificateAndPersonResponse> {
        return this.httpClient.post<CreateCertificateAndPersonResponse>("/api/PermitPerson/AddNewPersonToPermit", command);
    }

    public findPerson(query: FindCertifiedPersonQuery): Observable<FindCertifiedPersonResult> {
        const options = {
            params: {
                query: query.query,
                isPremium: query.isPremium,
            }
        };

        return this.httpClient.get<FindCertifiedPersonResult>("/api/Certificate/SearchCertificate", options);
    }

    public getRecentTeamMembers(permitId: number, skip: number, take: number, includeColleagues: boolean, query: string): Observable<unknown[]> {
        const options = {
            params: {
                permitId,
                skip,
                take,
                includeColleagues,
                query
            },
        };

        return this.httpClient.get<PermitPerson[]>("/api/PermitPerson/GetRecentTeamMembers", options);
    }

    public getTeam(permitId: number): Observable<PermitTeam> {
        const options = {
            params: {
                permitId: permitId,
            },
        };

        return this.httpClient.get<PermitTeam>("/api/PermitPerson/GetTeam", options);
    }

    public removePerson(command: RemovePersonFromPermitCommand) {
        return this.httpClient.delete<BaseValidationResult>("/api/PermitPerson/RemovePerson", { body: command });
    }

    public toggleAllowSwitching(command: ToggleAllowSwitchingCommand) {
        return this.httpClient.post<BaseValidationResult>("/api/PermitPerson/ToggleAllowSwitching", command);
    }

    public setFireWatchersNotNeeded(command: FireWatchersNotNeededCommand) {
        return this.httpClient.post<BaseValidationResult>("/api/PermitPerson/FireWatchersNotNeeded", command);
    }

    public signPermitWithSignature(command: SignPermitWithSignatureCommand) {
        return this.httpClient.post<BaseValidationResult>("/api/PermitPerson/SignPermitWithSignature", command);
    }

}
